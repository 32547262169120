(() => {
   const bind_submit_event = ($wrapper) => {
       $wrapper.on('submit', '.js-ajax-form', function(event) {
           const $form = $(this);
           const action = $form.attr('action');
           event.preventDefault();
           jQuery.post(
               action,
               $form.serialize(),
               function(response) {
                   $form.replaceWith(response);
                   // bind_submit_event($form);
               }
           );
           event.preventDefault();
       });
   };

   jQuery('.js-newsletter-section' ).each(function() {
       const $wrapper = $(this);
       bind_submit_event($wrapper);
   });
})();